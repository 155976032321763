import React, { Suspense, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Resources.scss";
import Menu from "../../components/Menu/Menu";
import Loader from "../../components/Loader/Loader";
import usePageTracking from "../../usePageTracking";

function Resources() {
  const { t } = useTranslation();
  const [explorers, setExplorers] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const [tools, setTools] = useState([]);
  const [community, setCommunity] = useState([]);
  const [whitepaper, setWhitepaper] = useState([]);
  const [graphics, setGraphics] = useState([]);

  useEffect(() => {
    fetch("/data/explorers.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setExplorers(jsonData);
      });

    fetch("/data/exchanges.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setExchanges(jsonData);
      });
  
    fetch("/data/tools.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setTools(jsonData);
      });
    
    fetch("/data/community.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setCommunity(jsonData);
      });
    
    fetch("/data/whitepaper.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setWhitepaper(jsonData);
      });

    fetch("/data/graphics.json")
      .then((res) => res.json())
      .then((jsonData) => {
        setGraphics(jsonData);
      });
  }, []);

  usePageTracking();

  return (
    <div className="ResourcesPage">
      <Menu />
      <div className="hero hero--thin">
        <h1 className="hero__title">{t("resourcesPage.title")}</h1>
        <p className="hero__description">{t("resourcesPage.description")}</p>
        <div className="hero__actions">
          <a href="#exchanges" className="hero__actions__action hero__actions__action--white">
            {t("resourcesPage.actions.action1")}
          </a>
          <a href="#blockexplorers" className="hero__actions__action hero__actions__action--white">
            {t("resourcesPage.actions.action2")}
          </a>
          <a href="#communities" className="hero__actions__action hero__actions__action--white">
            {t("resourcesPage.actions.action3")}
          </a>
          <a href="#whitepaper" className="hero__actions__action hero__actions__action--white">
            {t("resourcesPage.actions.action4")}
          </a>
        </div>
      </div>

      <div className="main">
        <div className="anchor" data-id="exchanges"></div>
        <div className="container">
          <h2 className="title title--green">
            {t(exchanges.title)}
          </h2>
          {exchanges.description ? <p className="description">{t(exchanges.description)}</p> : null}
          <div className="blocks-list">
            {exchanges.resources && exchanges.resources.map(exchange => (
              <a href={exchange.url}  target="_blank" rel="noopener noreferrer" className="blocks-list__block">
                <h4 className="blocks-list__block__title">{exchange.title}</h4>
                <img
                  className="blocks-list__block__img"
                  src={exchange.image}
                  alt={exchange.title}
                />
                <div className="blocks-list__block__type">
                  {t(exchange.type)}
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
 
      <div className="main">
        <div className="anchor" data-id="blockexplorers"></div>
        <div className="container">
          <h2 className="title title--green">
            {t(explorers.title)}
          </h2>
          {explorers.categories && explorers.categories.map(category => (
            <div>
              <h3 className="title title--green">
                {category.title ? t(category.title) : null}
              </h3>
              <div className="blocks-list">
                {category.resources.map(resource => (
                  <a href={resource.url}  target="_blank" rel="noopener noreferrer" className="blocks-list__block">
                    <h4 className="blocks-list__block__title">{resource.title}</h4>
                    <img className="blocks-list__block__img" src={resource.image} alt={resource.title} />
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="main">
        <div className="anchor" data-id="tools"></div>
        <div className="container">
          <h2 className="title title--green">
            {t(tools.title)}
          </h2>
          <div className="blocks-list">
            {tools.resources && tools.resources.map(tool => (
              <a href={tool.url}  target="_blank" rel="noopener noreferrer" className="blocks-list__block">
                <h4 className="blocks-list__block__title">
                  {t(tool.title)}
                </h4>
                <img className="blocks-list__block__img" src={tool.image} alt={t(tool.title)}/>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="main">
        <div className="anchor" data-id="communities"></div>
        <div className="container">
          <h2 className="title title--green">
            {t(community.title)}
          </h2>
          <div className="blocks-list">
            {community.resources && community.resources.map(community => (
              <a href={community.url}  target="_blank" rel="noopener noreferrer" className="blocks-list__block">
                <h4 className="blocks-list__block__title">{community.title}</h4>
                <img className="blocks-list__block__img" src={community.image} alt={community.title} />
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="main">
        <div className="anchor" data-id="whitepaper"></div>
        <div className="container">
          <h2 className="title title--green">
            {t(whitepaper.title)}
          </h2>
          <div className="blocks-list">
            {whitepaper.resources && whitepaper.resources.map(whitepaper => (
              <a href={whitepaper.url}  target="_blank" rel="noopener noreferrer" className="blocks-list__block">
                <h4 className="blocks-list__block__title">
                  {t(whitepaper.title)}
                </h4>
                <img className="blocks-list__block__img" src={whitepaper.image} alt={whitepaper.title} />
              </a>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Resources />
    </Suspense>
  );
}

export default HoF;
