import React, { useEffect, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import "./Menu.scss";
import { getCurrentFlag, getCurrentLanguage } from "../../helpers/Language";

function Menu() {
  const { t } = useTranslation();
  const triggerHeight = 100;
  const [isFixed, setIsFixed] = useState(window.scrollY > triggerHeight);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > triggerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    });

    if (window.scrollY > triggerHeight) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  }, []);

  async function toggleMenu() {
    await setIsOpen(!isOpen);
  }

  function menuClass() {
    return classNames({
      menu: true,
      "menu--fixed": isFixed,
      "menu--open": isOpen,
    });
  }

  return (
    <>
      <div className="menu-trigger" onClick={toggleMenu}>
        <img src="/img/menu.png" alt="" />
      </div>
      <nav className={menuClass()}>
        <div className="menu__close" onClick={toggleMenu}>
          <img src="/img/back.png" alt="" />
        </div>
        <div className="container">
          {isFixed && (
            <Link to="/">
              <img
                src="/img/logo-menu-white.png"
                alt="Peercoin"
                className="menu__logo"
              />
            </Link>
          )}
          <ul className="menu__main">
            <li>
              <Link to="/">{t("menuComponent.links.home.title")}</Link>
            </li>
            <li>
              <Link to="/resources">
                {t("menuComponent.links.resources.title")}
              </Link>
              <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu">
                <li>
                  <a href="/resources#exchanges" className="submenu">
                    <img src="/img/icons/exchange_green.png" alt="" />
                    {t("menuComponent.links.resources.exchanges")}
                  </a>
                </li>
                <li>
                  <a href="/resources#blockexplorers" className="submenu">
                    <img src="/img/icons/block_green.png" alt="" />
                    {t("menuComponent.links.resources.blockExplorers")}
                  </a>
                </li>
                <li>
                  <a href="/resources#whitepaper" className="submenu">
                    <img src="/img/icons/whitepaper_green.png" alt="" />
                    {t("menuComponent.links.resources.whitepaper")}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <span>{t("menuComponent.links.project.title")}</span>
              <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu">
                <li>
                  <a href="https://gitlab.com/pandacoin/" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/gitlab.png" alt="" />
                    {t("menuComponent.links.project.gitlab")}
                  </a>
                </li>
                <li>
                  <a href="https://github.com/DigitalPandacoin/" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/github_green.png" alt="" />
                    {t("menuComponent.links.project.github")}
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/@Pandacoin_PND" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/blog_green.png" alt="" />
                    {t("menuComponent.links.project.blog")}
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/wallet">
                <span>{t("menuComponent.links.wallet.title")}</span>
              </Link>
            </li>
            <li>
              <span>{t("menuComponent.links.community.title")}</span>
              <img src="/img/icon-menu-arrow-down.svg" alt="" />
              <ul className="menu__main__submenu">
                <li>
                  <a href="https://twitter.com/PandaCoinPND" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/twitter_green.png" alt="" />
                    {t("menuComponent.links.community.twitter")}
                  </a>
                </li>
                <li>
                  <a href="https://t.me/pandacoin" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/telegram_green.png" alt="" />
                    {t("menuComponent.links.community.telegram")}
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/Pandacoin.PND/" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/facebook_green.png" alt="" />
                    {t("menuComponent.links.community.facebook")}
                  </a>
                </li>
                <li>
                  <a href="https://www.reddit.com/r/PandacoinPND" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/reddit_green.png" alt="" />
                    {t("menuComponent.links.community.reddit")}
                  </a>
                </li>
                <li>
                  <a href="https://discord.gg/9UAXtm2ApS" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/discord_green.png" alt="" />
                    {t("menuComponent.links.community.discord")}
                  </a>
                </li>
                <li>
                  <a href="https://bitcointalk.org/index.php?topic=1960307.0" className="submenu" target="_blank" rel="noopener noreferrer">
                    <img src="/img/icons/bitcoinchat_green.png" alt="" />
                    {t("menuComponent.links.community.bitcointalk")}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

function HoF() {
  return (
    <Suspense fallback={<Loader open={true} />}>
      <Menu />
    </Suspense>
  );
}

export default HoF;
